<template lang="pug">
  .code-form
    Input(
      type='text'
      v-model='code[0]'
      size="large"
      @on-change="checkInput(0)"
      ref="code2fa-0"
    )
    Input(
      type='text'
      v-model='code[1]'
      size="large"
      @on-change="checkInput(1)"
      ref="code2fa-1"
    )
    Input(
      type='text'
      v-model='code[2]'
      size="large"
      @on-change="checkInput(2)"
      ref="code2fa-2"
    )
    Input(
      type='text'
      v-model='code[3]'
      size="large"
      @on-change="checkInput(3)"
      ref="code2fa-3"
    )
    Input(
      type='text'
      v-model='code[4]'
      size="large"
      @on-change="checkInput(4)"
      ref="code2fa-4"
    )
    Input(
      type='text'
      v-model='code[5]'
      size="large"
      @on-change="checkInput(5)"
      ref="code2fa-5"
    )
</template>

<script>
export default {
  name: 'auth-form',
  data () {
    return {
      code: [null, null, null, null, null, null],
      loading: false
    }
  },
  props: {
    onSendAuth: Function
  },
  computed: {},
  methods: {
    async doLogin () {
      this.loading = true
      await this.onLogin(this.form)
      this.loading = false
    },
    checkInput (i) {
      let myCode = this.code[i]
      // if (!/\d+/.test(myCode)) {
      //   this.code[i] = null
      //   const ref = `code2fa-${i}`
      //   this.$refs[ref].focus()
      //   return
      // }
      myCode = myCode.replace(/[^\d]/ig, '')

      if (!myCode.length) {
        this.code[i] = null
        return
      }
      const len = this.code.length
      myCode = `${myCode}`.split('').slice(0, len - i)

      for (const c of myCode) {
        if (i === this.code.length) break
        this.code[i] = c
        i++
      }
      if (i <= this.code.length - 1) {
        const ref = `code2fa-${i}`
        this.code[i] = null
        this.$refs[ref].focus()
        return
      }

      this.loading = true
      this.onSendAuth(this.code.join(''))
    }
  }
}
</script>
<style lang="sass">
.code-form
  margin: -8px 0 8px
  display: flex
  justify-content: center
  .ivu-input-large
    width: 38px
    margin: 0 4px
    font-size: 24px
    text-align: center
</style>
