<template lang='pug'>
.login
  .login-con
    Card(
      :title='$t("Welcome to {appName}", { appName: appName || $t("APP NAME") })'
      :bordered='false'
      v-if="!need2FA"
    )
      .form-con
        login-form(
          :on-login="handleSubmit"
        )
    Card(
      :title='$t("Please input authentication code")'
      :bordered='false'
      v-if="need2FA"
    )
      .form-con
        AuthForm(
          :on-send-auth="handle2FA"
        )
    .version {{ appVersion }}
</template>

<script>
import LoginForm from '~c/login-form'
import AuthForm from '~c/authentication-form'
import { mapActions, mapGetters } from 'vuex'
import to from 'await-to-js'
const appName = process.env.VUE_APP_NAME
export default {
  name: 'login',
  components: {
    LoginForm,
    AuthForm
  },
  data () {
    return {
      appName,
      need2FA: false
    }
  },
  computed: {
    ...mapGetters('auth', ['userInfo', 'appVersion'])
  },
  methods: {
    ...mapActions('auth', ['login', 'auth2fa']),
    async handleSubmit ({ username, pass }) {
      const [err, res] = await to(this.login({ username, pass }))
      if (err) {
        console.log(err)
        this.$Message.error(this.$t(err.message || 'Login fail. Try again later.'))
        return
      }

      const { token, need2FA = false } = res
      if (need2FA) {
        this.need2FA = token
        return
      }

      this.processLogin(token)
    },
    async handle2FA (code) {
      const [err, res] = await to(this.auth2fa({ code, token: this.need2FA }))
      if (err) {
        this.$Message.error(this.$t('Invalid authentication code'))
        setTimeout(() => {
          this.need2FA = false
        }, 500)
        return
      }
      const { token } = res
      this.processLogin(token)
    },
    processLogin (token) {
      // this.need2FA = false
      this.$socket.emit('login', { token })
      this.$i18n.locale = localStorage.getItem('languageFamily') || 'zh-tw'
      this.$Message.success(this.$t('Login successful.'))
      this.$router.push(this.$route.query.redirect || '/dashboard')
    }
  }
}
</script>
<style lang='sass' scoped>
  html, body, #app
    height: 100%
    width: 100%

  .login
    width: 100%
    height: 100%
    // background-image: url('../../assets/images/login-bg.jpg')
    background-size: cover
    background-position: center
    // background-color: #5cadff
    background-color: #515a6e
    position: relative

    .version
      color: #fff
      font-size: 12px
      text-align: right
      padding-top: 6px

    &-con
      position: absolute
      left: 50%
      top: 50%
      margin-left: -150px
      transform: translateY(-60%)
      width: 300px

      &-header
        font-size: 16px
        font-weight: 300
        text-align: center
        padding: 30px 0

      .form-con
        padding: 10px 0 0

      .login-tip
        font-size: 10px
        text-align: center
        color: #c3c3c3
</style>
