<template lang="pug">
  Form(ref='loginForm', :model='form', :rules='rules', @keydown.enter.native="submitLogin")
    FormItem.form-item(prop='username')
      Input(type='text', v-model='form.username', :placeholder="$t('Please input username')", style="width: 100%;")
        Icon(type='ios-person-outline', slot='prepend')
    FormItem.form-item(prop='pass')
      Input(type='password', v-model='form.pass', :placeholder="$t('Please input password')", style="width: 100%;")
        Icon(type='ios-lock-outline', slot='prepend')
    FormItem.form-item
      Button(@click='submitLogin()' style="width: 100%;" :loading="loading") {{$t('Signin')}}
</template>

<script>
export default {
  name: 'LoginForm',
  data () {
    return {
      form: {
        username: '',
        pass: ''
      },
      rules: {
        username: [
          { required: true, message: this.$t('Please fill in the user name'), trigger: 'blur' }
        ],
        pass: [
          { required: true, message: this.$t('Please fill in the password'), trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  props: {
    onLogin: Function
  },
  computed: {},
  methods: {
    async doLogin () {
      this.loading = true
      await this.onLogin(this.form)
      this.loading = false
    },
    submitLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // this.$Message.success('Success!')
          this.doLogin()
          // this.$emit('on-success-valid', this.form)
        } else {
          this.$Message.error('Password/Account are required')
        }
      })
    }
  }
}
</script>
<style lang='sass' scoped>
.form-item
  // margin-bottom: 12px
</style>
